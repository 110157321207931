import { Container, inject } from 'aurelia-framework';
import { FilesRepository }   from 'modules/administration/files/services/repository';
import { Downloader }        from 'resources/services/downloader';

@inject(Downloader, FilesRepository)
export class FileCustomListingCell {
    /**
     * Constructor.
     *
     * @param downloader
     * @param filesRepository
     */
    constructor(downloader, filesRepository) {
        this.downloader      = downloader;
        this.filesRepository = filesRepository;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.model           = model;
        this.column          = model.column;
        this.row             = model.row;
        this.filesRepository = model.column.filesRepository ? Container.instance.get(model.column.filesRepository) : this.filesRepository;
        this.alwaysVisible   = model.column.alwaysVisible;
        this.icon            = model.column.icon;
        this.action          = () => model.column.action ? model.column.action(this.row) : this.downloadFile(this.row.file_id);
    }

    /**
     * Downloads the file
     */
    downloadFile(model) {
        // remove the file extension from the file name
        const fileName = this.row ? this.row.file.display_name.split('.').slice(0, -1).join('.') : model.existing_file.display_name.split('.').slice(0, -1).join('.');

        this.filesRepository
            .download(this.row ? this.row.file_id : model.file_id)
            .then((blob) => this.downloader.download(blob, fileName, this.row ? this.row.file.extension : model.existing_file.extension));
    }
}
