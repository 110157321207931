import { ProcessStatus } from 'modules/core/models/process-status';

export class ProcessingStatusListingCell {

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.model  = model;
        this.column = this.model.column;
        this.row    = this.model.row;

        if (this.model.row[this.column.data]) {
            this.icon  = ProcessStatus.getIconByStatusId(this.model.row[this.column.data].id);
            this.title = this.model.row[this.column.data].message;
        }
    }

    /**
     * Handles custom action button
     *
     * @param action
     * @param row
     *
     * @returns {*}
     */
    doCustomAction(action, row) {
        if (action instanceof Function) {
            return action(row);
        }
    }
}
