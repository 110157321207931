import { inject }                       from 'aurelia-framework';
import { BooleanStatus }                from 'modules/administration/models/boolean-status';
import { UserEntityTeamLotsRepository } from 'modules/administration/users/user-entity-team-lot/services/repository';
import { LotInterventionSelection }     from 'modules/concessions/lot-intervention-selection/models/lot-intervention-selection';
import { HighwaysRepository }           from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { InterventionTypesRepository }  from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { LotInterventionsRepository }   from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { ConcessionsRepository }        from 'modules/management/specifications/concessions/services/repository';
import { AppContainer }                 from 'resources/services/app-container';

@inject(AppContainer, ConcessionsRepository, HighwaysRepository, InterventionTypesRepository, UserEntityTeamLotsRepository, LotInterventionsRepository)
export class LotInterventionSelectionFormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param concessionsRepository
     * @param highwaysRepository
     * @param interventionTypesRepository
     * @param userEntityTeamLotsRepository
     * @param lotInterventionsRepository
     */
    constructor(appContainer, concessionsRepository, highwaysRepository, interventionTypesRepository, userEntityTeamLotsRepository, lotInterventionsRepository) {
        this.appContainer                 = appContainer;
        this.concessionsRepository        = concessionsRepository;
        this.highwaysRepository           = highwaysRepository;
        this.interventionTypesRepository  = interventionTypesRepository;
        this.userEntityTeamLotsRepository = userEntityTeamLotsRepository;
        this.lotInterventionsRepository   = lotInterventionsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LotInterventionSelection}
     */
    model() {
        let model = new LotInterventionSelection();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    async schema(viewModel, readonly = false) {
        this.concession_id = {
            type:         'select2',
            key:          'concession_id',
            label:        'form.field.concession',
            size:         12,
            required:     false,
            remoteSource: () => this.concessionsRepository.allByLoggedUser(),
            observers:    [
                async (newValue, oldValue) => {
                    if (!newValue) {
                        viewModel.model.highway_id           = null;
                        viewModel.model.intervention_type_id = null;
                    }

                    await this.highwaysRepository.lotGroupedAll({
                        concession_id: newValue,
                    }).then((response) => {
                        this.highway_id.options = this.highway_id.instance.transformOptionsObject(response);
                    });

                    await this.lot_intervention_id.instance.fetchData();
                },
            ],
        };

        this.highway_id = {
            type:         'select2',
            key:          'highway_id',
            label:        'form.field.highway-road',
            size:         12,
            required:     false,
            remoteSource: () => this.highwaysRepository.lotGroupedAll({
                concession_id: viewModel.model.concession_id,
            }),
            observers:    [
                (newValue, oldValue) => {
                    this.intervention_type_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.highwaysRepository.interventionTypes(newValue, { filter_by_logged_user: true }).then((response) => {
                            this.intervention_type_id.options = response;
                            this.intervention_type_id.instance.enable(response.length);
                        });
                    } else {
                        this.intervention_type_id.options = [{ id: null, name: this.appContainer.i18n.tr('form.field.select-first-highway-road'), status_id: BooleanStatus.INACTIVE }];
                    }
                },
            ],
        };

        this.intervention_type_id = {
            type:         'select2',
            key:          'intervention_type_id',
            label:        'form.field.intervention-type',
            size:         12,
            required:     false,
            remoteSource: () => {
                return viewModel.model.highway_id
                    ? this.highwaysRepository.interventionTypes(viewModel.model.highway_id, { filter_by_logged_user: true })
                    : Promise.resolve([{ id: null, name: this.appContainer.i18n.tr('form.field.select-first-highway-road'), status_id: BooleanStatus.INACTIVE }]);
            },
            observers:    [
                (newValue, oldValue) => {
                    return this.lotInterventionsRepository.lotGroupedAll({
                        concession_id:        viewModel.model.concession_id,
                        highway_id:           viewModel.model.highway_id,
                        intervention_type_id: newValue,
                    }).then((response) => {
                        this.lot_intervention_id.options = this.lot_intervention_id.instance.transformOptionsObject(response);
                    });
                },
            ],
        };

        this.lot_intervention_id = {
            type:         'select2',
            key:          'lot_intervention_id',
            label:        'form.field.lot-intervention',
            size:         12,
            remoteSource: () => this.lotInterventionsRepository.lotGroupedAll({
                concession_id:        viewModel.model.concession_id,
                highway_id:           viewModel.model.highway_id,
                intervention_type_id: viewModel.model.intervention_type_id,
            }),
            observers:    [
                (newValue, oldValue) => {
                    if (newValue) {

                        return this.userEntityTeamLotsRepository.searchActiveTeams({
                            lot_intervention_id:   newValue,
                            filter_by_logged_user: true,
                        }).then((response) => {
                            if (response.length > 1) {
                                this.team_id.options = response;
                                this.team_id.hidden  = false;
                            } else {
                                this.team_id.hidden     = true;
                                viewModel.model.team_id = null;
                            }
                        });
                    } else {
                        this.team_id.hidden     = true;
                        viewModel.model.team_id = null;
                    }
                },
            ],
        };

        this.team_id = {
            type:         'select2',
            key:          'team_id',
            label:        'form.field.entity-team',
            hidden:       !(await this.hasMoreThanOneTeam(viewModel.model.lot_intervention_id)),
            size:         12,
            remoteSource: () => {
                return viewModel.model.lot_intervention_id
                    ? this.userEntityTeamLotsRepository.searchActiveTeams({
                        lot_intervention_id:   viewModel.model.lot_intervention_id,
                        filter_by_logged_user: true,
                    }) : Promise.resolve([]);
            },
        };

        return [
            [this.concession_id],
            [this.highway_id],
            [this.intervention_type_id],
            [this.lot_intervention_id],
            [this.team_id],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalSchema(viewModel) {
        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Returns true if the lot intervention has more than one team
     *
     * @param lotInterventionId
     *
     * @returns {boolean}
     */
    async hasMoreThanOneTeam(lotInterventionId) {
        if (!lotInterventionId) {
            return false;
        }

        const response = await this.userEntityTeamLotsRepository.searchActiveTeams({
            lot_intervention_id:   lotInterventionId,
            filter_by_logged_user: true,
        })

        return response.length > 1;
    }

}
